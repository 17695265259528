/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    h2: "h2",
    em: "em",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Design_Patterns"
  }, "The Gang of Four"), " defined the prototype pattern as follows:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Specify the kinds of objects to create using a prototypical instance, and create new objects by copying this prototype."), "\n"), "\n", React.createElement(_components.p, null, "In other words, the behavior is inherited from specific instances, not classes. The most popular language using this type of inheritance is JavaScript (until the class-based system arrived in ES6). While Ruby uses class-based inheritance, its object model is flexible enough to allow us to implement basic prototypal inheritance."), "\n", React.createElement(_components.p, null, "We're going to implement a T-Rex, starting from a generic animal, through a dinosaur, ending on a specific instance of a Tyrannosaurus."), "\n", React.createElement(_components.h2, null, "Defining methods on objects (not classes)"), "\n", React.createElement(_components.p, null, "Since Ruby allows to extend classes at runtime (the concept of so-called ", React.createElement(_components.em, null, "open classes"), "), it's not surprising that it's possible to extend instances as well. There are three synonymous ways of doing it:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "# by using def…\nstr1 = \"test string\"\ndef str1.big\n  upcase + \"!\"\nend\n\n# …which is equivalent to calling def within the instance:\nstr1.instance_eval do\n  def big\n    upcase + \"!!\"\n  end\nend\n\n# by using define_singleton_method\nstr2 = \"test string\"\nstr2.define_singleton_method :big do\n  upcase + \"!\"\nend\n\n# by extending the eigenclass/singleton class\nstr3 = \"test string\"\nclass << str3\n  def big\n    upcase + \"!\"\n  end\nend\n\nstr1.big\n# => \"TEST STRING!!\"\nstr2.big\n# => \"TEST STRING!\"\nstr3.big\n# => \"TEST STRING!\"\n")), "\n", React.createElement(_components.p, null, "All three ways do the same thing, but the third form gives a hint on how Ruby handles adding a method to a specific object: it creates an “anonymous” class, inserts the method to it and prepends this class to the object's inheritance chain. This class is called eigenclass, or ghost class, or singleton class."), "\n", React.createElement(_components.p, null, "You can easily verify it: calling ", React.createElement(_components.code, null, "str1.singleton_methods"), ", ", React.createElement(_components.code, null, "str2.singleton_methods"), ", ", React.createElement(_components.code, null, "str3.singleton_methods"), " will return ", React.createElement(_components.code, null, "[:big]"), "."), "\n", React.createElement(_components.p, null, "If you'd like to learn more about singleton classes, I highly recommend reading ", React.createElement(_components.a, {
    href: "https://medium.com/@ethan.reid.roberts/rubys-anonymous-eigenclass-putting-the-ei-in-team-ebc1e8f8d668"
  }, "Ruby’s Anonymous Eigenclass: Putting the “Ei” in Team"), "."), "\n", React.createElement(_components.h2, null, "Back to the prototypal inheritance"), "\n", React.createElement(_components.p, null, "Let's start by implementing a generic animal prototype."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "animal = Object.new\ndef animal.taxonomic_name\n  \"Animalia\"\nend\n\ndef animal.breathe\n  puts \"[inhales] [exhales]\"\nend\n\ndef animal.__proto__\n  nil\nend\n\ndef animal.taxonomic_rank\n  __proto__ ? (__proto__.taxonomic_rank + [taxonomic_name]).uniq : [taxonomic_name]\nend\n")), "\n", React.createElement(_components.p, null, "Notice how we started by creating an empty object and continued by defining methods on that specific instance. We can also see a hint of the things to come: our objects will store the prototype inside ", React.createElement(_components.code, null, "__proto__"), " method and will determine the animal's ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Taxonomic_rank"
  }, "taxonomic rank"), " by traversing the prototype chain."), "\n", React.createElement(_components.p, null, "Our animal kingdom needs a constructor method. Unsurprisingly, we'll call it ", React.createElement(_components.code, null, "new"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "def animal.new\n  prototype_obj = self\n  new_obj = clone\n  new_obj.define_singleton_method :__proto__ do\n    prototype_obj\n  end\n  new_obj\nend\n")), "\n", React.createElement(_components.p, null, "You may wonder: why did we use ", React.createElement(_components.code, null, "define_singleton_method"), " instead of any other form? Well, we wanted ", React.createElement(_components.code, null, "__proto__"), " method to return the value of ", React.createElement(_components.code, null, "self"), " from the prototype's ", React.createElement(_components.code, null, "new"), " method. However, using ", React.createElement(_components.code, null, "def"), "/", React.createElement(_components.code, null, "class"), " switches the lexical scope, meaning that any values previously defined in the block are not visible in the body of the method we're defining using the two keywords. Fortunately, closures “remember” the local values from the scope they were defined in, so using ", React.createElement(_components.code, null, "define_singleton_method"), " with a block (closure) allows us to access the value of ", React.createElement(_components.code, null, "prototype_obj"), " inside the method we're defining."), "\n", React.createElement(_components.p, null, "Let's test our implementation by creating a dinosaur from the animal prototype:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "dinosaur = animal.new\ndef dinosaur.taxonomic_name\n  \"Dinosauria\"\nend\n\ndef dinosaur.walk\n  puts \"[walks]\"\nend\n\ndef dinosaur.run\n  puts \"[walks faster]\"\nend\n\ndinosaur.breathe\n# [inhales] [exhales]\ndinosaur.run\n# [walks faster]\ndinosaur.taxonomic_rank\n# => [\"Animalia\", \"Dinosauria\"]\nanimal.run\n# NoMethodError (undefined method `run' for #<Object:0x00007fb0780de618>)\n")), "\n", React.createElement(_components.p, null, "So far, so good – our inheritance works correctly. Since we defined the ability to run on the dinosaur, a generic animal cannot do it, and attempt to do so raises the expected exception."), "\n", React.createElement(_components.p, null, "Now let's finalize the chain by defining a theropod, tyrannosaurus and a specific instance of T-Rex:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "theropod = dinosaur.new\ndef theropod.taxonomic_name\n  \"Theropoda\"\nend\ndef theropod.run\n  puts \"[runs pretty fast]\"\nend\n\ntyrannosaurus = theropod.new\ndef tyrannosaurus.taxonomic_name\n  \"Tyrannosaurus\"\nend\n\nt_rex = tyrannosaurus.new\nt_rex.run\n# [runs pretty fast]\nt_rex.breathe\n# [inhales] [exhales]\nt_rex.taxonomic_rank\n# => [\"Animalia\", \"Dinosauria\", \"Theropoda\", \"Tyrannosaurus\"]\n")), "\n", React.createElement(_components.p, null, "Works as expected."), "\n", React.createElement(_components.p, null, "Finally, let's pretend that our implementation of dinosaurs is class-based:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "Animal = animal\nDinosaur = dinosaur\nTheropod = theropod\nTyrannosaurus = tyrannosaurus\n\n# ...\n\nt = Tyrannosaurus.new\nt.taxonomic_rank\n# =>[\"Animalia\", \"Dinosauria\", \"Theropoda\", \"Tyrannosaurus\"]\n")), "\n", React.createElement(_components.p, null, "Without reading the implementation details, nobody would think that this is not a class-based system."), "\n", React.createElement(_components.h2, null, "What are classes in Ruby anyway?"), "\n", React.createElement(_components.p, null, "It's often repeated that in Ruby ", React.createElement(_components.em, null, "everything is an object"), ". No Rubyist should be surprised that ", React.createElement(_components.code, null, "5.class"), " returns ", React.createElement(_components.code, null, "Integer"), " and that we can represent blocks of code as ", React.createElement(_components.code, null, "Proc"), " objects. Even methods are objects (and operators are just methods):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "5.0.method(:round).class\n# => Method\n5.0.method(:+).class\n=> Method\n")), "\n", React.createElement(_components.p, null, "What about classes? They are objects, too!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "Class.class\n# => Class\nClass.class.class\n# => Class\nClass.ancestors\n# => [Class, Module, Object, Kernel, BasicObject]\nA = Class.new do\n  def test_method\n     \"Test\"\n  end\nend\nB = Class.new(A)\nB.ancestors\n# => [B, A, Object, Kernel, BasicObject]\nB.new.test_method\n# => \"Test\"\n")), "\n", React.createElement(_components.h2, null, "Wrapping up"), "\n", React.createElement(_components.p, null, "Is prototypal inheritance any useful in Ruby? Probably not. Some people would say that skipping expensive constructors in favor of fast object cloning or opting out of costly method lookup in the inheritance chain and using local methods instead (cloned from the prototype) can have performance benefits, but that is a weak argument for any high-level language. Still, playing with the object model by implementing an un-Ruby idiom is an excellent opportunity to systematize more advanced concepts about this language."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
